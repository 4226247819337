/**
* TABLE OF CONTENTS
* 1. Globals and Super Containers
* 2. Inputs, Buttons and Links
* 3. Meter Reading list
* 4. Dialog, Chart Dialog
**/


/**1. Globals and Super Containers**/
:root{
  --color-dark-blue: rgb(0, 55, 85);
  --color-blue-light: hsl(210, 29%, 97%);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: var(--color-dark-blue);
}
body, html{
  height: 100%;
  width: 100%;
}
.App{
  display: grid;
  background-color: #fefefe;
}
.nav{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  background-color: rgba(255, 255, 255, 0.747);
  z-index: 10;
}
/**End-Globals**/

/**2. Inputs, Buttons and Links**/
.input-wrapper{
  display: flex;
}
.input{
  --w: 70vw;
  outline: none;
  border: 2px solid rgba(0, 55, 85, 0);
  padding: 1rem;
  text-indent: 32px;
  width: var(--w);
  display: flex;
  margin: 1rem auto;
  box-shadow: 0 0 16px rgba(0, 55, 85, 0.247);
  transition: all ease-out 500ms;
}
.input::placeholder{
  color: rgba(0, 0, 0, 0.281);
}
.input:hover, .input:focus{
  border-color: #3b3b3b67;
  box-shadow: 0 0 64px rgba(0, 0, 0, 0.212);
}

.btn{
  outline: none;
  border: none;
  padding: 1rem 3rem;
  margin: 1rem 0;
  cursor: pointer;
}
.btn--primary{
  background-color: var(--color-dark-blue);
  color: #fff;
}
.btn--icon{
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--color-blue-light);
  position: absolute;
  right: 2rem;
  z-index: 10;
}
.icon{
  max-width: 100%;
  max-height: 100%;
}
.img{
  object-fit: contain;
}
.img--logo{
  width: 140px;
  mix-blend-mode: multiply;
}

/**End Inputs, Buttons and Links***/

/**3. Meter Reading list***/
.list{
  margin-top: 18vh;
  padding: 0 1rem;
  animation: slide-up ease-out 500ms;
}
.sub-title{
  font-size: 14px;
  color: rgba(0,0,0,0.68);
  margin-left: 1rem;
}
.reading{
  margin-bottom: 8px;
}
.meter{
  display: grid;
  padding: 1rem;
  width: 100%;
  background-color: #f7f7f7;
  margin: .5rem auto;
  cursor: pointer;
  transform: scale(0.99);

  transition: all ease-out 500ms;

  animation: slide-up ease-out 500ms;
}
.meter:hover{
  transform: scale(1);
  box-shadow: 0 0 10px rgba(41, 41, 41, 0.13);
  z-index: 2;
}
.list:hover .meter:not(:hover){
  filter: grayscale(1);
  opacity: 0.76;
  transition: ease-out all 500ms;
  z-index: -1;
}
.meter__id{
  color: var(--color-dark-blue);
  font-size: 14px;
  margin-bottom: 8px;
}
.meter__reading{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.units{
  font-size: 10px;
  font-weight: normal;
}
.list-stats{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 10;
}
/**End Meter Reading list***/

/**4. Dialog, Chart Dialog, Banner, Warnings***/

dialog{
  position: fixed;
  width: 100vw;
  height: 100vh;
  border: none;
  outline: none;
  overflow-y: auto;

  padding: 2rem;

  animation: slide-up 500ms ease-out;
  z-index: 999;

}
.container{
  position: relative;
  display: grid;
  gap: 1rem;
}
.container__head{
  background-color: #fff;
}
.chart{
  display: flex;
  width: 100%;
  max-height: 600px !important;
  min-height: 60vh;
}
.warning{
  --w: 80vw;
  --h: 65vh;
  position: absolute;
  background-color: var(--color-blue-light);
  width: var(--w);
  height: var(--h);
  display: flex;
  margin-left: calc(50% - var(--w) / 2);
  margin-top: 4rem;

  justify-content: center;
  align-items: center;
  font-size: 2rem;

  color: rgba(0, 55, 85, 0.363);
  animation: slide-up 500ms ease-out;
}

/**End Dialog, Chart Dialog***/

/**5. Animations ***/

@keyframes slide-up{
  from{
    opacity: .56;
    top: 100vh;
  }
  to{
    opacity: 1;
    top: 0;
  }
}


/**End Animations ***/
